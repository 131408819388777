<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        What element in period 3 has exactly
        <stemble-latex :content="pState" />? Write only the atomic symbol for that element.
      </p>
      <div class="custom-chemical-symbol-width">
        <chemical-symbol-input
          v-model="inputs.input1"
          :prepend-text="'$\\text{Element:}$'"
          :disabled="!allowEditing"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question34',
  components: {
    ChemicalSymbolInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {input1: null},
    };
  },
  computed: {
    pPosition() {
      return this.taskState.getValueBySymbol('arrayVariable').content;
    },
    pState() {
      if (this.pPosition.value === 0) {
        return '$\\textcolor{red}{\\textbf{one empty}}\\textbf{ p-orbital}$';
      } else if (this.pPosition.value === 1) {
        return '$\\textcolor{red}{\\textbf{two empty}}\\textbf{ p-orbitals}$';
      } else if (this.pPosition.value === 2) {
        return '$\\textcolor{red}{\\textbf{three half-filled}}\\textbf{ p-orbitals}$';
      } else if (this.pPosition.value === 3) {
        return '$\\textcolor{red}{\\textbf{one filled}}\\textbf{ p-orbital}$';
      } else if (this.pPosition.value === 4) {
        return '$\\textcolor{red}{\\textbf{two filled}}\\textbf{ p-orbitals}$';
      } else if (this.pPosition.value === 5) {
        return '$\\textcolor{red}{\\textbf{three filled}}\\textbf{ p-orbitals}$';
      }
    },
  },
};
</script>
